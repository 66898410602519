import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie';
import { DateParserService, StreamI18nService } from 'stream-chat-angular';
import { DAYS_OF_WEEK } from 'angular-calendar';
import { BehaviorSubject, firstValueFrom, map } from 'rxjs';
import { MonitoringService } from '../../../shared/services/monitoring/monitoring.service';
import localeDe from '@angular/common/locales/de';
import dayjs from 'dayjs';
import locale_en from 'dayjs/locale/en';
import locale_de from 'dayjs/locale/de';
import locale_en_gb from 'dayjs/locale/en-gb';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import minMax from 'dayjs/plugin/minMax';
import isoWeek from 'dayjs/plugin/isoWeek';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { registerLocaleData } from '@angular/common';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import arraySupport from 'dayjs/plugin/arraySupport';

type SupportedLanguage = 'en' | 'de';
type FullLocale = 'en-gb' | 'en-us' | 'de-de';

// For some reason, en does not have these formats like the others
const LOCALE_US_WITH_FORMATS = {
  ...locale_en,
  formats: {
    LTS: 'h:mm:ss A',
    LT: 'h:mm A',
    L: 'MM/DD/YYYY',
    LL: 'MMMM D, YYYY',
    LLL: 'MMMM D, YYYY h:mm A',
    LLLL: 'dddd, MMMM D, YYYY h:mm A',
  },
};

@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  private static _dayjsInstance = dayjs;

  private readonly SUPPORTED_LANGUAGES: readonly SupportedLanguage[] = [
    'en',
    'de',
  ] as const;
  private readonly LOCALE_COOKIE_KEY = 'iclocale';
  private readonly DEFAULT_WEEK_CONFIG = {
    dow: DAYS_OF_WEEK.MONDAY,
    doy: 0,
  };

  private currentLocaleSubject = new BehaviorSubject<SupportedLanguage>('en');
  public readonly currentLocale$ = this.currentLocaleSubject.asObservable();
  public readonly currentFullLocale$ = this.currentLocale$.pipe(
    map((locale) => this.detectFullLocale(locale))
  );

  private readonly defaultLocale = locale_en_gb;
  private readonly localeMap: Record<FullLocale, ILocale> = {
    'en-gb': locale_en_gb,
    'en-us': LOCALE_US_WITH_FORMATS,
    'de-de': locale_de,
  } as const;

  constructor(
    private translateService: TranslateService,
    private cookies: CookieService,
    private streamI18nService: StreamI18nService,
    private dateParserService: DateParserService
  ) {
    // Initialize dayjs plugins
    LocaleService._dayjsInstance.extend(utc);
    LocaleService._dayjsInstance.extend(timezone);
    LocaleService._dayjsInstance.extend(localizedFormat);
    LocaleService._dayjsInstance.extend(utc);
    LocaleService._dayjsInstance.extend(localizedFormat);
    LocaleService._dayjsInstance.extend(customParseFormat);
    LocaleService._dayjsInstance.extend(arraySupport);
    LocaleService._dayjsInstance.extend(minMax);
    LocaleService._dayjsInstance.extend(isoWeek);
  }

  public static get dayjsInstance() {
    return LocaleService._dayjsInstance;
  }

  initializeLocale(): void {
    registerLocaleData(localeDe);

    const savedLanguage = this.cookies.get(this.LOCALE_COOKIE_KEY);
    const initialLanguage = this.isSupportedLanguage(savedLanguage)
      ? savedLanguage
      : this.SUPPORTED_LANGUAGES[0];

    this.setLocale(initialLanguage);
  }

  async setLocale(
    language: string | null,
    userTimezone?: string
  ): Promise<boolean> {
    const resolvedLanguage = language || this.getCurrentLocale();

    if (!this.isSupportedLanguage(resolvedLanguage)) {
      console.warn(`Unsupported language: ${resolvedLanguage}`);
      return false;
    }

    // Handle timezone
    if (userTimezone) {
      console.log('Setting user timezone:', userTimezone);
      LocaleService._dayjsInstance.tz.setDefault(userTimezone);
      console.log('Dayjs timezone:', LocaleService._dayjsInstance.tz.guess());
    }

    // Get the full locale based on browser settings and language
    const fullLocale = this.detectFullLocale(resolvedLanguage);

    try {
      // Update all localization services
      this.updateDayjsLocale(fullLocale);
      this.configureStreamDateParser();

      // First set the default language and wait for translations
      this.translateService.setDefaultLang(resolvedLanguage);
      await firstValueFrom(
        this.translateService.getTranslation(resolvedLanguage)
      );

      // Set stream translations based on the successfully loaded language
      const actualLanguage =
        this.translateService.currentLang || resolvedLanguage;
      if (actualLanguage === 'de') {
        this.streamI18nService.setTranslation('de', streamTranslationsDE);
      } else {
        this.streamI18nService.setTranslation('en');
      }

      this.saveLocaleToCookie(resolvedLanguage);
      this.currentLocaleSubject.next(resolvedLanguage);

      return true;
    } catch (error) {
      MonitoringService.captureMessage('Failed to update translations', {
        extra: error,
      });
      return false;
    }
  }

  private updateDayjsLocale(fullLocale: string): void {
    const options = {
      weekStart: DAYS_OF_WEEK.MONDAY,
    };

    const localeKey = fullLocale.toLowerCase() as FullLocale;
    const localeConfig = this.localeMap[localeKey] || this.defaultLocale;

    LocaleService._dayjsInstance.locale({
      ...localeConfig,
      ...options,
    });
  }

  private saveLocaleToCookie(language: SupportedLanguage): void {
    this.cookies.put(this.LOCALE_COOKIE_KEY, language, {
      expires: LocaleService._dayjsInstance().add(1, 'month').toDate(),
      path: '/',
    });
  }

  getCurrentLocale(): SupportedLanguage {
    return this.currentLocaleSubject.value;
  }

  getCurrentFullLocale(): string {
    return this.detectFullLocale(this.getCurrentLocale());
  }

  private detectFullLocale(language: SupportedLanguage): string {
    if (language === 'de') {
      return 'de-de';
    }

    // language must be 'en' at this point
    const browserLocales = navigator.languages || [navigator.language];
    const firstLocale = browserLocales[0]?.toLowerCase();

    return firstLocale?.startsWith('en-us') ? 'en-us' : 'en-gb';
  }

  private isSupportedLanguage(
    language: string | null
  ): language is SupportedLanguage {
    return (
      language !== null &&
      this.SUPPORTED_LANGUAGES.includes(language as SupportedLanguage)
    );
  }

  private configureStreamDateParser() {
    // Set each parser directly with localized formats
    this.dateParserService.customDateParser = (date: Date) => {
      return dayjs(date).calendar(null, {
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        nextWeek: 'dddd',
        lastDay: '[Yesterday]',
        lastWeek: '[Last] dddd',
        sameElse: 'L', // Localized date format
      });
    };

    this.dateParserService.customDateTimeParser = (date: Date) => {
      return dayjs(date).calendar(null, {
        sameDay: '[Today at] LT',
        nextDay: '[Tomorrow at] LT',
        nextWeek: 'dddd [at] LT',
        lastDay: '[Yesterday at] LT',
        lastWeek: '[Last] dddd [at] LT',
        sameElse: 'L [at] LT', // Localized date and time format
      });
    };

    this.dateParserService.customTimeParser = (date: Date) => {
      return dayjs(date).format('LT'); // Localized time format
    };
  }
}

// tslint:disable-next-line:max-line-length
// Translated from https://raw.githubusercontent.com/GetStream/stream-chat-angular/refs/heads/master/projects/stream-chat-angular/src/assets/i18n/en.ts
const streamTranslationsDE = {
  '1 reply': '1 Antwort',
  'Attach files': 'Dateien anhängen',
  Cancel: 'Abbrechen',
  'Channel Missing': 'Kanal fehlt',
  Close: 'Schließen',
  'Close emoji picker': 'Emoji-Auswahl schließen',
  'Commands matching': 'Übereinstimmende Befehle',
  'Connection failure, reconnecting now...':
    'Verbindungsfehler, wird neu verbunden...',
  Delete: 'Löschen',
  Delivered: 'Zugestellt',
  'Edit Message': 'Nachricht bearbeiten',
  'Edit message request failed': 'Bearbeiten der Nachricht fehlgeschlagen',
  'Emoji matching': 'Übereinstimmende Emojis',
  'Empty message...': 'Leere Nachricht...',
  'Error adding flag': 'Fehler beim Hinzufügen der Markierung',
  'Error connecting to chat, refresh the page to try again.':
    'Fehler bei der Verbindung zum Chat, Seite neu laden und erneut versuchen',
  'Error deleting message': 'Fehler beim Löschen der Nachricht',
  'Error loading reactions': 'Fehler beim Laden der Reaktionen',
  'Error muting a user ...': 'Fehler beim Stummschalten eines Benutzers...',
  'Error pinning message': 'Fehler beim Anheften der Nachricht',
  'Error removing message pin': 'Fehler beim Entfernen der Nachricht',
  'Error unmuting a user ...': 'Fehler beim Aufheben der Stummschaltung...',
  'Error uploading file': 'Fehler beim Hochladen der Datei "{{ name }}"',
  'Error uploading file, maximum file size exceeded':
    'Fehler beim Hochladen von "{{ name }}", maximale Dateigröße {{ limit }} überschritten',
  'Error uploading file, extension not supported':
    'Fehler beim Hochladen von "{{ name }}", Dateityp {{ ext }} wird nicht unterstützt',
  'Error deleting attachment': 'Fehler beim Löschen des Anhangs',
  'Error · Unsent': 'Nachricht konnte nicht gesendet werden',
  'Error: {{ errorMessage }}': 'Fehler: {{ errorMessage }}',
  Flag: 'Markieren',
  'Message Failed': 'Nachricht fehlgeschlagen',
  'Message Failed · Unauthorized': 'Nicht berechtigt, Nachricht zu senden',
  'Message Failed · Click to try again':
    'Nachricht konnte nicht gesendet werden, zum erneuten Versuch klicken',
  'Message deleted': 'Nachricht gelöscht',
  'Message has been successfully flagged':
    'Nachricht wurde erfolgreich markiert',
  'Message pinned': 'Nachricht angeheftet',
  'Message unpinned': 'Nachricht nicht mehr angeheftet',
  Mute: 'Stummschalten',
  New: 'Neu',
  'New Messages!': 'Neue Nachrichten!',
  'No results found': 'Keine Ergebnisse gefunden',
  'Nothing yet...': 'Noch nichts...',
  'Only visible to you': 'Nur für dich sichtbar',
  'Open emoji picker': 'Emoji-Auswahl öffnen',
  'People matching': 'Übereinstimmende Personen',
  'Pick your emoji': 'Wähle dein Emoji',
  Pin: 'Anheften',
  'Pinned by': 'Angeheftet von',
  Reply: 'Antwort zitieren',
  'Reply to Message': 'Auf Nachricht antworten',
  Search: 'Suchen',
  'Searching...': 'Suche...',
  Send: 'Senden',
  'Send message request failed': 'Senden der Nachricht fehlgeschlagen',
  'Sending...': 'Senden...',
  'Slow Mode ON': 'Langsamer Modus an',
  'Start of a new thread': 'Beginn eines neuen Threads',
  'This message was deleted...': 'Diese Nachricht wurde gelöscht...',
  Thread: 'Thread-Antwort',
  'Type your message': 'Gib deine Nachricht ein',
  Unmute: 'Stummschaltung aufheben',
  Unpin: 'Lösen',
  'Wait until all attachments have uploaded':
    'Warte, bis alle Anhänge hochgeladen wurden',
  'You have no channels currently': 'Du hast derzeit keine Kanäle',
  "You've reached the maximum number of files":
    'Du hast die maximale Anzahl von Dateien erreicht',
  live: 'live',
  'this content could not be displayed':
    'Dieser Inhalt konnte nicht angezeigt werden',
  '{{ commaSeparatedUsers }} and {{ moreCount }} more':
    '{{ commaSeparatedUsers }} und {{ moreCount }} mehr',
  '{{ commaSeparatedUsers }}, and {{ lastUser }}':
    '{{ commaSeparatedUsers }} und {{ lastUser }}',
  '{{ firstUser }} and {{ secondUser }}':
    '{{ firstUser }} und {{ secondUser }}',
  '{{ imageCount }} more': '{{ imageCount }} mehr',
  '{{ memberCount }} members': '{{ memberCount }} Mitglieder',
  '{{ replyCount }} replies': '{{ replyCount }} Antworten',
  '{{ user }} has been muted': '{{ user }} wurde stummgeschaltet',
  '{{ user }} has been unmuted': '{{ user }} wurde wieder freigeschaltet',
  '{{ watcherCount }} online': '{{ watcherCount }} online',
  '🏙 Attachment...': '🏙 Anhang...',
  'Connection error': 'Verbindungsfehler',
  'Load more': 'Mehr laden',
  failed: 'fehlgeschlagen',
  retry: 'wiederholen',
  test: 'erfolgreich',
  'Sending links is not allowed in this conversation':
    'Das Senden von Links ist in dieser Unterhaltung nicht erlaubt',
  "You can't send messages in this channel":
    'Du kannst in diesem Kanal keine Nachrichten senden',
  "You can't send thread replies in this channel":
    'Du kannst in diesem Kanal keine Antworten senden',
  'Message not found': 'Nachricht nicht gefunden',
  'No chats here yet…': 'Noch keine Chats hier...',
  'user is typing': '{{ user }} tippt',
  'users are typing': '{{ users }} tippen',
  'Error loading channels': 'Fehler beim Laden der Kanäle',
  'See original (automatically translated)':
    'Original anzeigen (automatisch übersetzt)',
  'See translation': 'Übersetzung anzeigen',
  'Mark as unread': 'Als ungelesen markieren',
  'Error marking message as unread': 'Fehler beim Markieren als ungelesen',
  'Error, only the first {{count}} message can be marked as unread':
    'Fehler, nur die ersten {{count}} Nachrichten können als ungelesen markiert werden',
  'Unread messages': 'Ungelesene Nachrichten',
  '{{count}} unread messages': '{{count}} ungelesene Nachrichten',
  '{{count}} unread message': '{{count}} ungelesene Nachricht',
  'This message did not meet our content guidelines':
    'Diese Nachricht entspricht nicht unseren Inhaltsrichtlinien',
  'Send Anyway': 'Trotzdem senden',
  Edited: 'Bearbeitet',
  'Error playing audio': 'Fehler beim Abspielen der Audiodatei',
  'Copy text': 'Text kopieren',
  'Please grant permission to use microhpone':
    'Bitte erteile die Erlaubnis, das Mikrofon zu benutzen',
  'Error starting recording': 'Fehler beim Starten der Aufnahme',
  'An error has occurred during recording':
    'Während der Aufnahme ist ein Fehler aufgetreten',
  'Media recording not supported': 'Medienaufzeichnung wird nicht unterstützt',
};

// Export with a different name
export const zonedDayJs = LocaleService.dayjsInstance;
