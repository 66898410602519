import {
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Channel, Event, FormatMessageResponse } from 'stream-chat';
import {
  ChatClientService,
  DefaultStreamChatGenerics,
  getChannelDisplayText,
  getMessageTranslation,
  getReadBy,
  MessageService,
  StreamAvatarModule,
  StreamChatModule,
} from 'stream-chat-angular';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AsyncPipe, NgIf, NgTemplateOutlet } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ConversationPreviewComponent } from '../conversation-preview/conversation-preview.component';
import {
  getOtherMemberIfOneToOneChannelById,
  getOtherMemberUserIfOneToOneChannelById,
} from '../../../helpers/stream_chat_helper';

@Component({
  selector: 'app-stream-conversation-preview',
  templateUrl: './stream-conversation-preview.component.html',
  styleUrls: ['./stream-conversation-preview.component.scss'],
  imports: [
    StreamAvatarModule,
    AsyncPipe,
    NgTemplateOutlet,
    NgIf,
    TranslateModule,
    StreamChatModule,
    ConversationPreviewComponent,
  ],
})
export class StreamConversationPreviewComponent implements OnInit, OnDestroy {
  /**
   * The channel to be displayed
   */
  @Input() channel: Channel<DefaultStreamChatGenerics> | undefined;
  @Input() currentUserIsTherapist: boolean = false;
  @Input() showTime: boolean = true;
  @Input() truncateSize: number = 150;
  @Output() conversationClick = new EventEmitter<void>();

  isUnreadMessageWasCalled = false;
  isUnread = false;
  unreadCount: number | undefined;
  latestMessageText: string = '';
  latestMessageStatus?: 'delivered' | 'read';
  latestMessageTime?: Date;
  latestMessage?: FormatMessageResponse<DefaultStreamChatGenerics>;
  latestMessageHasAppointment: boolean = false;
  latestMessageHasGenericAttachment: boolean = false;
  latestMessageHasInvoice: boolean;
  displayAs: 'text' | 'html';
  userId?: string;
  private subscriptions: (Subscription | { unsubscribe: () => void })[] = [];
  private canSendReadEvents = true;

  otherUserIsDeactivated = false;
  otherUserIsShadowBanned = false;
  avatarSrc: string | undefined;

  constructor(
    private ngZone: NgZone,
    private chatClientService: ChatClientService,
    messageService: MessageService
  ) {
    this.displayAs = messageService.displayAs;
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.chatClientService.user$.subscribe((user) => {
        if (user?.id !== this.userId) {
          this.userId = user?.id;
          const otherMember = getOtherMemberIfOneToOneChannelById(
            this.userId!,
            this.channel!
          );
          const otherUser = otherMember?.user;
          this.avatarSrc = otherUser?.image;
          this.otherUserIsDeactivated = !!otherUser?.deactivated_at;
          this.otherUserIsShadowBanned = !!otherMember?.shadow_banned;
        }
      })
    );
    const messages = this.channel?.state?.latestMessages;
    if (messages && messages.length > 0) {
      this.setLatestMessage(messages[messages.length - 1]);
    }
    this.updateUnreadState();
    const capabilities =
      (this.channel?.data?.own_capabilities as string[]) || [];
    this.canSendReadEvents = capabilities.indexOf('read-events') !== -1;
    this.subscriptions.push(
      this.channel!.on('message.new', this.handleMessageEvent.bind(this))
    );
    this.subscriptions.push(
      this.channel!.on('message.updated', this.handleMessageEvent.bind(this))
    );
    this.subscriptions.push(
      this.channel!.on('message.deleted', this.handleMessageEvent.bind(this))
    );
    this.subscriptions.push(
      this.channel!.on('channel.truncated', this.handleMessageEvent.bind(this))
    );
    this.subscriptions.push(
      this.channel!.on('message.read', () =>
        this.ngZone.run(() => {
          this.isUnreadMessageWasCalled = false;
          this.updateUnreadState();
        })
      )
    );
    this.subscriptions.push(
      this.chatClientService.events$
        .pipe(
          filter(
            (e) =>
              e.eventType === 'notification.mark_unread' &&
              this.channel!.id === e.event?.channel_id
          )
        )
        .subscribe(() => {
          this.ngZone.run(() => {
            this.isUnreadMessageWasCalled = true;
            this.updateUnreadState();
          });
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  onConversationClick() {
    this.conversationClick.emit();
  }

  get showMatchedBadge() {
    return (
      this.channel?.data?.started_via_matching_service &&
      this.currentUserIsTherapist
    );
  }

  get title() {
    if (!this.channel) {
      return '';
    }
    return getChannelDisplayText(
      this.channel,
      this.chatClientService.chatClient.user!
    );
  }

  get latestMessageTextWithTruncation() {
    return this.latestMessageText.length > this.truncateSize
      ? this.latestMessageText.slice(0, this.truncateSize) + '...'
      : this.latestMessageText;
  }

  private handleMessageEvent(event: Event) {
    this.ngZone.run(() => {
      if (this.channel?.state.latestMessages.length === 0) {
        this.latestMessage = undefined;
        this.latestMessageStatus = undefined;
        this.latestMessageText = 'streamChat.Nothing yet...';
        this.latestMessageTime = undefined;
        return;
      }
      const latestMessage =
        this.channel?.state.latestMessages[
          this.channel?.state.latestMessages.length - 1
        ];
      if (!event.message || latestMessage?.id !== event.message.id) {
        return;
      }
      this.setLatestMessage(latestMessage);
      this.updateUnreadState();
    });
  }

  private setLatestMessage(
    message?: FormatMessageResponse<DefaultStreamChatGenerics>
  ) {
    this.latestMessage = message;
    if (message?.deleted_at) {
      this.latestMessageText = '(deleted)';
    } else {
      if (message?.attachments && message.attachments.length) {
        this.latestMessageHasAppointment = !!message.attachments.find(
          (a) => a.type === 'appointment'
        );
        this.latestMessageHasInvoice = !!message.attachments.find(
          (a) => a.type === 'invoice'
        );
        this.latestMessageHasGenericAttachment = !(
          this.latestMessageHasInvoice || this.latestMessageHasAppointment
        );
      }
      if (message?.text) {
        this.latestMessageText =
          getMessageTranslation(
            message,
            this.channel,
            this.chatClientService.chatClient.user
          ) || message.text;
      }
    }
    if (this.latestMessage && this.latestMessage.type === 'regular') {
      this.latestMessageTime = this.latestMessage.created_at;
    } else {
      this.latestMessageTime = undefined;
    }
  }

  private updateUnreadState() {
    if (
      this.channel &&
      this.latestMessage &&
      this.latestMessage.user?.id === this.userId &&
      this.latestMessage.status === 'received' &&
      this.latestMessage.type === 'regular'
    ) {
      this.latestMessageStatus =
        getReadBy(this.latestMessage, this.channel).length > 0
          ? 'read'
          : 'delivered';
    } else {
      this.latestMessageStatus = undefined;
    }
    if (!this.canSendReadEvents) {
      this.unreadCount = 0;
      this.isUnread = false;
      return;
    }
    this.unreadCount = this.channel!.countUnread();
    this.isUnread = !!this.unreadCount;
  }
}
