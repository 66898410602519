import { Injectable } from '@angular/core';
import * as amplitude from '@amplitude/analytics-browser';
import { User } from '../../../entities/user.model';
import { CookieService } from 'ngx-cookie';
import { environment } from '../../../../environments/environment';
import { LogLevel } from '@amplitude/analytics-types';
import { Identify } from '@amplitude/analytics-browser';
import { RetentionCouponId } from '../../../components/settings/service/settings.service';

const keyDeprecatedAnalyticsUserId = 'ic_analytics_user_id'; // do not use
const keyAnalyticsUserId = 'ic_analytics_user_id_v2';
const keyAnalyticsUserKind = 'ic_analytics_user_kind';

@Injectable({
  providedIn: 'root',
})
export class AmplitudeAnalyticsService {
  constructor(private cookieService: CookieService) {
    // Any change to this configuration should be applied to the Amplitude instance in the Rails app.
    amplitude.init(
      environment.amplitudeApiKey,
      undefined, // user is not known yet (see `setUser`)
      {
        domain: environment.domain_name,
        minIdLength: 1, // Default is 5 but IC ids starts with 1 character
        logLevel: LogLevel.Warn,
      }
    );
  }

  setUser(user: User): void {
    this.setAnalyticsUserId(user.analytics_user_id);
    this.setUserType(user.type);
  }

  private setAnalyticsUserId(analyticsUserId: string) {
    // Cookie to share the analytics data with the Amplitude instance in the rails app
    this.cookieService.put(keyAnalyticsUserId, String(analyticsUserId));
    // Remove previous cookie to avoid confusion
    this.cookieService.remove(keyDeprecatedAnalyticsUserId);
    amplitude.setUserId(String(analyticsUserId));
  }

  setUserType(userKind: 'Therapist' | 'Client') {
    const analyticsUserKind = userAnalyticsKind(userKind);

    this.cookieService.put(keyAnalyticsUserKind, analyticsUserKind);
    const identifyObj = new Identify();
    identifyObj.set('kind', analyticsUserKind);
    amplitude.identify(identifyObj);
  }

  clearUser(): void {
    amplitude.setUserId(undefined);
    // Cookie to share the user-id with Amplitude instance in the rails app
    this.cookieService.remove(keyAnalyticsUserId);
    // Remove previous cookie to avoid confusion
    this.cookieService.remove(keyDeprecatedAnalyticsUserId);
  }

  trackMessageSent(properties: {
    source_page: string;
    therapist_user_id: string;
    conversation_messages_total: number;
    conversation_id?: string;
  }): void {
    amplitude.track('Message Sent', {
      source_page: properties.source_page,
      therapist_user_id: properties.therapist_user_id.toString(),
      conversation_messages_total: properties.conversation_messages_total,
      /* optional property, new messages don't have a conversation id yet */
      conversation_id: properties.conversation_id,
    });
  }

  trackLoginStarted(properties: { has_contact_intent: boolean }): void {
    amplitude.track('Login Started', {
      has_therapist_referral: properties.has_contact_intent,
    });
  }

  async trackAuthStarted(properties: { has_contact_intent: boolean }) {
    await amplitude.track('Auth Started', {
      has_therapist_referral: properties.has_contact_intent,
    }).promise;
  }

  trackAuthFinished(properties: { has_contact_intent: boolean }): void {
    amplitude.track('Auth Finished', {
      has_therapist_referral: properties.has_contact_intent,
    });
  }

  trackRegistrationStarted(): void {
    amplitude.track('Register Account Started');
  }

  async trackRegistrationFinished() {
    await amplitude.track('Register Account Finished').promise;
  }

  trackLoginFinished(properties: { has_contact_intent: boolean }): void {
    amplitude.track('Login Finished', {
      has_therapist_referral: properties.has_contact_intent,
    });
  }

  trackCreateAccountStarted(properties: { has_contact_intent: boolean }): void {
    amplitude.track('Create Account Started', {
      has_therapist_referral: properties.has_contact_intent,
    });
  }

  async trackCreateAccountFinished(properties: {
    has_contact_intent: boolean;
  }) {
    await amplitude.track('Create Account Finished', {
      has_contact_intent: properties.has_contact_intent,
    }).promise;
  }

  trackErrorEncountered(properties: { kind: string; name: string }) {
    amplitude.track('Error Encountered', {
      kind: properties.kind,
      name: properties.name,
    });
  }

  trackDowngradeScreenViewed(properties: { sub_screen: string }) {
    amplitude.track('Downgrade Screen Viewed', {
      sub_screen: properties.sub_screen,
    });
  }

  trackDowngradeCanceled() {
    amplitude.track('Downgrade Canceled');
  }

  trackResubscribeClicked() {
    amplitude.track('Resubscribe Clicked');
  }

  trackChangePlansClicked() {
    amplitude.track('Change Plans Clicked');
  }

  trackDowngradeSurveySubmitted(properties: {
    reasons: Set<string>;
    comment: string;
  }) {
    amplitude.track('Downgrade Survey Submitted', {
      reasons: Array.from(properties.reasons),
      comment: properties.comment ? properties.comment : null,
    });
  }

  trackDowngradeConfirmed() {
    amplitude.track('Downgrade Confirmed');
  }

  trackRetentionCouponRejected(type: RetentionCouponId) {
    amplitude.track('Retention Coupon Rejected', {
      coupon_type: type,
    });
  }

  trackRetentionCouponAccepted(type: RetentionCouponId) {
    amplitude.track('Retention Coupon Accepted', {
      coupon_type: type,
    });
  }

  trackVideoCallInitiated(properties: {
    source_page:
      | 'dashboard_sidebar'
      | 'messages_page'
      | 'calendar_page'
      | 'waiting_room_page'
      | 'dashboard';
    kind: 'instant_private_call' | 'scheduled_private_call' | 'open_call';
  }) {
    amplitude.track('Video Call Initiated', {
      source_page: properties.source_page,
      kind: properties.kind,
    });
  }

  trackOpenedCommunity(properties: {
    source_page: 'dashboard_sidebar' | 'dashboard';
    source_action?: string;
  }) {
    amplitude.track('Community Opened', {
      source_page: properties.source_page,
      source_action: properties.source_action,
    });
  }

  announcementPrimaryCTAClicked(properties: { id: string; title: string }) {
    const safe = properties || {};
    amplitude.track('Announcement Primary CTA Clicked', {
      announcement_id: properties.id,
      announcement_title: properties.title,
    });
  }

  announcementCloseClicked(properties: { id: string; title: string }) {
    amplitude.track('Announcement Close Clicked', {
      announcement_id: properties.id,
      announcement_title: properties.title,
    });
  }

  trackAppointmentInteractedWith(properties: {
    kind:
      | 'declined'
      | 'inquiry_setup'
      | 'confirmed'
      | 'pay'
      | 'pay_and_confirm'
      | 'view_invoice'
      | 'cancel'
      | 'decline'
      | 'edit'
      | 'marked_eap_session_as_completed';
    source_page: 'messages_page' | 'calendar_page' | 'dashboard';
  }) {
    amplitude.track('Appointment Interacted With', {
      source_page: properties.source_page,
      kind: properties.kind,
    });
  }

  trackScheduleAppointmentStarted(properties: {
    source_page: 'messages_page' | 'calendar_page';
  }) {
    amplitude.track('Schedule Appointment Started', {
      source_page: properties.source_page,
    });
  }

  trackFavoritesViewed() {
    amplitude.track('Favorites Viewed');
  }

  trackFavoriteClicked(properties: { therapist_user_id: string }) {
    amplitude.track('Favorite Clicked', {
      therapist_user_id: properties.therapist_user_id,
    });
  }
}

function userAnalyticsKind(
  userKind: 'Therapist' | 'Client'
): 'therapist' | 'client' {
  switch (userKind) {
    case 'Client':
      return 'client';
    case 'Therapist':
      return 'therapist';
    default:
      throw new Error(`unknown user kind:${userKind}`);
  }
}
